import React, { useState } from "react";

const Contact = () => {
  const [form, setFormValues] = useState({
    fullName: "",
    email: "",
    ph: "",
    message: "",
  });
  const inputEvent = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormValues((prevValue) => {
      if (name === "fname") {
        return {
          fullName: value,
          email: prevValue.email,
          ph: prevValue.ph,
          message: prevValue.message,
        };
      } else if (name === "email") {
        return {
          fullName: prevValue.fullName,
          email: value,
          ph: prevValue.ph,
          message: prevValue.message,
        };
      } else if (name === "ph") {
        return {
          fullName: prevValue.fullName,
          email: prevValue.email,
          ph: value,
          message: prevValue.message,
        };
      } else if (name === "message") {
        return {
          fullName: prevValue.fullName,
          email: prevValue.email,
          ph: prevValue.ph,
          message: value,
        };
      }
    });
  };
  const resetForm = () => {
    setFormValues(() => {
      return { fullName: "", email: "", ph: "", message: "" };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let emailBody =
      form.fullName + ", <br />" + form.ph + " <br /> <br/>" + form.message;
    window.Email.send({
      Host: "nxtgenaroma.com",
      Username: "contact@nxtgenaroma.com",
      Password: "nxt#93528",
      To: "contact@nxtgenaroma.com",
      From: "contact@nxtgenaroma.com",
      Subject: "Contact email",
      Body: emailBody,
    }).then((message) => {
      alert(message);
      resetForm();
    });
  };
  return (
    <>
      <section id="header" className="d-flex align-items-center">
        <div className="container-fluid nav_bg">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-md-5 pt-1 pt-lg-0 order-3 order-lg-1 d-flex justify-content-center flex-column">
                  <h2>
                    <strong>Mr. Vaibhav Hadole</strong>
                  </h2>
                  <p>
                    Company Address :<br />
                    Plot No: J-36, MIDC Area, Malegaon, <br />
                    On Nashik-Pune Highway, Sinnar,<br></br> Dist-Nashik(MH) -
                    422113.
                  </p>
                  <p>
                    Contact Number:
                    <br />
                    +91-9422293528 <br />
                    +91-9422246701
                  </p>
                  <p>
                    Email:
                    <br />
                    nxtgenaroma@gmail.com <br />
                    contact@nxtgenaroma.com
                  </p>
                </div>
                <div className="col-md-5 order-1 order-lg-2 header-img">
                  <form id="contact-form" onSubmit={handleSubmit} method="POST">
                    <div className="form-group ">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        name="fname"
                        className="form-control"
                        onChange={inputEvent}
                        value={form.fullName}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        onChange={inputEvent}
                        value={form.email}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phone">Mobile</label>
                      <input
                        type="text"
                        name="ph"
                        className="form-control"
                        onChange={inputEvent}
                        value={form.ph}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea
                        className="form-control"
                        name="message"
                        rows="5"
                        onChange={inputEvent}
                        value={form.message}
                      ></textarea>
                    </div>
                    <div className="form-group"><button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
