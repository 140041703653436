import React from "react";
import CompoundImg from "./images/product.jpg";
const Product = () => {
  return (
    <>
      <section id="header" className="d-flex align-items-center">
        <div className="container-fluid nav_bg">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-md-2 order-2 order-lg-2 d-flex justify-content-center">
                  <img src={CompoundImg} alt="compound img" />
                </div>

                <div className="col-md-8 pt-1 pt-lg-0 order-1 order-lg-1 d-flex justify-content-center flex-column">
                  <h2>
                     We create Aroma that Enlight your day.
                   </h2>
                   <p>We are the manufacturer of β-Naphthol methyl ether widely
                    known as YARA YARA.</p>
                   <p>Yara Yara is white crystal powder solid having floral odour. YARA YARA mainly used in essence creation like Incense sticks, Detergent Powder, Bath Soaps and Perfumery Compounds.</p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;
